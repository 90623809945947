<!--
 * @Author: perseverance 1457194101@qq.com
 * @Date: 2023-09-23 10:25:32
 * @LastEditors: perseverance 1457194101@qq.com
 * @LastEditTime: 2024-09-20 20:04:09
 * @FilePath: \demo2\src\components\HelloWorld.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="body">
    <div class="container">
      <div class="logo"><img src="../assets/logo.png" /></div>
      <span class="title">Advanced Metering Infrastructure</span>
      <div class="con">
        <div class="item" @click="goRoute(1)">
          <div class="box">
            <img
              src="../assets/image1.png"
              style="width: 80%; margin-top: 10px"
            />
          </div>
          <div class="title2">HES</div>
        </div>
        <div class="item" @click="goRoute(2)">
          <div class="box">
            <img
              src="../assets/image2.png"
              style="width: 80%; margin-top: 30px"
            />
          </div>
          <div class="title2" style="margin-left: 10px">HES Visualization</div>
        </div>
        <div class="item" @click="goRoute(3)">
          <div class="box">
            <img
              src="../assets/image3.png"
              style="width: 80%; margin-top: 10px"
            />
          </div>
          <div class="title2">WMS</div>
        </div>
        <div class="item" @click="goRoute(4)">
          <div class="box">
            <img
              src="../assets/image4.png"
              style="width: 80%; margin-top: 10px"
            />
          </div>
          <div class="title2">AMS</div>
        </div>
        <div class="item" @click="goRoute(5)">
          <div class="box">
            <img
              src="../assets/image14.png"
              style="width: 60%; margin-top: 15px"
            />
          </div>
          <div class="title2" style="margin-left: 10px">Walk by System</div>
        </div>
        <div class="item" @click="goRoute(6)">
          <div class="box">
            <img
              src="../assets/image10.png"
              style="width: 80%; margin-top: 10px"
            />
          </div>
          <div class="title2">BGS</div>
        </div>
        <div class="item" @click="goRoute(7)">
          <div class="box">
            <img
              src="../assets/image13.png"
              style="width: 70%; margin-top: 20px"
            />
          </div>
          <div class="title2" style="margin-left: 10px">BGS Visualization</div>
        </div>
        <div class="item2">
          <!-- <div class="box">
            <img
              src="../assets/image5.png"
              style="width: 50%; margin-top: 20px"
            />
          </div>
          <div class="title2">MDMS</div> -->
        </div>
      </div>
      <!-- <div class="item" @click="goRoute(9)">
        <div class="box single">
          <img
            src="../assets/image9.png"
            style="width: 60%; margin-top: 30px"
          />
        </div>
        <div class="title3">BGS Visualization</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      // imageData: [
      //   {
      //     src: "../assets/image1.png",
      //     title: "HES",
      //   },
      //   {
      //     src: "../assets/image2.png",
      //     title: "HES Visualization",
      //   },
      //   {
      //     src: "../assets/image3.png",
      //     title: "WMS",
      //   },
      //   {
      //     src: "../assets/image4.png",
      //     title: "AMS",
      //   },
      //   {
      //     src: "../assets/image5.png",
      //     title: "MDMS",
      //   },
      //   {
      //     src: "../assets/image6.png",
      //     title: "HMS",
      //   },
      //   {
      //     src: "../assets/image7.png",
      //     title: "SMOC",
      //   },
      //   {
      //     src: "../assets/image8.png",
      //     title: "BGS",
      //   },
      // ],
    };
  },
  methods: {
    goRoute(val) {
      console.log(val);
      if (val == 1) {
        window.open("https://hes.greengiga.com", "_blank"); // 新窗口打开
      } else if (val == 2) {
        window.open("http://hesvisual.greengiga.com", "_blank"); // 新窗口打开
      } else if (val == 3) {
        window.open("http://wms.greengiga.com", "_blank"); // 新窗口打开
      } else if (val == 4) {
        window.open("http://ams.greengiga.com", "_blank"); // 新窗口打开
      } else if (val == 5) {
        window.open("http://hms.greengiga.com", "_blank"); // 新窗口打开
      } else if (val == 6) {
        window.open("https://www.bgrid.cn/", "_blank"); // 新窗口打开
      } else if (val == 7) {
        window.open("http://beevisual.bgrid.cn", "_blank"); // 新窗口打开
      } else if (val == 8) {
        // window.open("http//:hms.geengiga.com", "_blank"); // 新窗口打开
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.body {
  background: url("../assets/bgc.png"),
    linear-gradient(180deg, rgba(0, 152, 108, 1) 0%, rgba(0, 68, 68, 1) 100%);
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 102% 102%;
  background-position: center;
}
.container {
  max-width: 1200px; /* 设置最大宽度 */
  margin: 0 auto; /* 居中对齐 */
  padding: 20px; /* 内边距 */
}
.logo {
  margin-top: 4vh;
}
.title {
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 0px;
  line-height: 56.26px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  margin-top: 10px;
  vertical-align: top;
}
.con {
  margin-top: 6vh;
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
  justify-content: space-evenly; /* 平均分配空间 */
  width: 100%; /* 让容器宽度占满 */
}

.item {
  width: calc(25% - 55px);
  margin-bottom: 60px;
  cursor: pointer;
}
.item2 {
  width: calc(25% - 55px);
  margin-bottom: 60px;
  /* cursor: pointer; */
}
.box {
  height: 125px;
  width: 125px;
  background-color: rgba(255, 255, 255, 1);
  border: 2px solid transparent; /* 默认边框透明 */
  transition: border 0.3s; /* 添加过渡效果 */
  border-radius: 8px;
  text-align: center;
  color: white;
  line-height: 100px;
}
.box:hover {
  border: 2px solid #008461; /* 鼠标滑过时边框变为蓝色 */
}
.box2 {
  height: 100%;
  width: 50%;
  background: #000;
  margin: 0 auto;
}
.title2 {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 23.44px;
  color: rgba(255, 255, 255, 1);
  vertical-align: top;
  margin-left: 40px;
  margin-top: 18px;
}
.title3 {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 23.44px;
  color: rgba(255, 255, 255, 1);
  vertical-align: top;
  margin-left: 50px;
  margin-top: 5px;
}
.single {
  margin-left: 43px !important;
}
</style>
