var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('span',{staticClass:"title"},[_vm._v("Advanced Metering Infrastructure")]),_c('div',{staticClass:"con"},[_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goRoute(1)}}},[_vm._m(1),_c('div',{staticClass:"title2"},[_vm._v("HES")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goRoute(2)}}},[_vm._m(2),_c('div',{staticClass:"title2",staticStyle:{"margin-left":"10px"}},[_vm._v("HES Visualization")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goRoute(3)}}},[_vm._m(3),_c('div',{staticClass:"title2"},[_vm._v("WMS")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goRoute(4)}}},[_vm._m(4),_c('div',{staticClass:"title2"},[_vm._v("AMS")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goRoute(5)}}},[_vm._m(5),_c('div',{staticClass:"title2",staticStyle:{"margin-left":"10px"}},[_vm._v("Walk by System")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goRoute(6)}}},[_vm._m(6),_c('div',{staticClass:"title2"},[_vm._v("BGS")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goRoute(7)}}},[_vm._m(7),_c('div',{staticClass:"title2",staticStyle:{"margin-left":"10px"}},[_vm._v("BGS Visualization")])]),_c('div',{staticClass:"item2"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('img',{staticStyle:{"width":"80%","margin-top":"10px"},attrs:{"src":require("../assets/image1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('img',{staticStyle:{"width":"80%","margin-top":"30px"},attrs:{"src":require("../assets/image2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('img',{staticStyle:{"width":"80%","margin-top":"10px"},attrs:{"src":require("../assets/image3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('img',{staticStyle:{"width":"80%","margin-top":"10px"},attrs:{"src":require("../assets/image4.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('img',{staticStyle:{"width":"60%","margin-top":"15px"},attrs:{"src":require("../assets/image14.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('img',{staticStyle:{"width":"80%","margin-top":"10px"},attrs:{"src":require("../assets/image10.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('img',{staticStyle:{"width":"70%","margin-top":"20px"},attrs:{"src":require("../assets/image13.png")}})])
}]

export { render, staticRenderFns }